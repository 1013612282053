<script>
  export let close = ()=>null;
</script>

<div class='modulars' on:click={e=>e.target.classList.contains('modulars')&&close()}>
  <div class='container'>
    <div id='closemodular' on:click={close}>X</div>
    <div class="modular">
      <slot></slot>
    </div>
  </div>  
</div>
